import customReplyService from '@/services/customReplyService';
const _ = require('lodash');
import CreateCustomReplyPopup from '@/components/createCustomAutoReplyPopup/createCustomAutoReplyPopup';

export default {
  name: 'RustomReplyListComponent',
  components: {
    CreateCustomReplyPopup
  },
  data() {
    return {
      loading: false,
      syncingWithFacebook: false,

      pageOptions: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      },

      customReplyList: null
    };
  },

  methods: {
    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchCustomReplys(true);
    },

    async fetchCustomReplys(fetchCount) {
      try {
        this.loading = true;

        let params = {
          fetchCount: fetchCount,
          pageOptions: this.pageOptions
        };

        let response = await customReplyService.getList(params);

        this.customReplyList = response.data.list;

        for (let i = 0; i < this.customReplyList.length; i++) {
          this.customReplyList[i].updated_at = this.customReplyList[i].updated_at ? this.getDisplayTime(this.customReplyList[i].updated_at) : 'N/A';
        }

        if (fetchCount) this.pageOptions.total = response.data.count;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch details. Please contact support.');
      } finally {
        this.loading = false;
      }
    },

    createCustomReply() {
      this.$refs.CreateCustomReplyPopup.showDialog(null);
    },

    editCustomReply(index) {
      let id = this.customReplyList[index].id;
      this.$refs.CreateCustomReplyPopup.showDialog(id);
    },

    async deleteCustomReply(index) {
      let customReply = this.customReplyList[index];

      this.$swal({
        title: `Delete Custom Reply? <br /> "${customReply.name}"`,
        text: `You won't be able to undo this!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#4b5563',
        confirmButtonText: 'Yes, delete it!'
      }).then(async () => {
        try {
          // Delete Custom Reply
          await customReplyService.delete(customReply.id);
          this.fetchCustomReplys(true);
        } catch (err) {
          this.errorToast('Failed to delete. Please contact support. ');
        }
      });
    }
  },

  mounted() {
    this.fetchCustomReplys(true);
  }
};
