import customReplyService from '@/services/customReplyService';

export default {
  props: {},
  components: {},
  data() {
    return {
      popupTitle: 'Create Custom Auto Reply',
      dialogVisible: false,
      savingChanges: false,

      isEditMode: false,
      customReplyId: null,

      keywordInputHolder: null,

      form: null,
      formTemplate: {
        name: '',
        questions: [''],
        keywords: [],
        response: {
          // Should be send session message format.
          text: '',
          buttons: []
        }
      },
      formErrors: {},
      formErrorsTemplate: {
        name: '',
        questions: [],
        response: {
          text: '',
          buttons: []
        }
      }
    };
  },

  methods: {
    onValidateAndSave() {
      let isValid = true;
      this.formErrors = JSON.parse(JSON.stringify(this.formErrorsTemplate));

      // Validate Name.
      if (!this.form.name) {
        this.formErrors.name = 'Name cannot be empty.';
        isValid = false;
      } else {
        this.formErrors.name = '';
      }

      // Validate Questions.
      for (let i = 0; i < this.form.questions.length; i++) {
        let question = this.form.questions[i];
        if (!question) {
          this.formErrors.questions.push('Input cannot be empty.');
          isValid = false;
        } else {
          this.formErrors.questions.push('');
        }
      }

      // Validate Response Text.
      if (!this.form.response.text) {
        this.formErrors.response.text = 'Response text cannot be empty.';
        isValid = false;
      } else {
        this.formErrors.response.text = '';
      }

      // Validate Buttons.
      for (let i = 0; i < this.form.response.buttons.length; i++) {
        let button = this.form.response.buttons[i];
        if (!button.text) {
          this.formErrors.response.buttons.push('Button text cannot be empty.');
          isValid = false;
        } else {
          this.formErrors.response.buttons.push('');
        }
      }

      if (isValid) {
        this.saveChanges();
      } else {
        this.warningToast('Please fill all the required fields.');
      }
    },

    async saveChanges() {
      try {
        this.savingChanges = true;

        // If no keyword mentioned, set null for keyword.
        let params = JSON.parse(JSON.stringify(this.form));
        if (params.keywords.length === 0) {
          params.keywords = null;
        }

        let response = null;
        if (this.isEditMode) {
          response = await customReplyService.update(this.customReplyId, params);
        } else {
          response = await customReplyService.create(this.form);
        }
        console.log('Response', response);

        if (response.data.success) {
          this.dialogVisible = false;

          if (this.isEditMode) {
            this.successToast('Custom reply updated successfully.');
          } else {
            this.successToast('Custom reply created successfully.');
          }

          this.$emit('onRefresh');
        } else {
          this.errorToast('Failed to create custom reply. Please contact Support.');
        }
      } catch (e) {
        this.errorToast('Failed to create custom reply. Please contact Support.');
        console.error('Failed to create custom reply', e);
      } finally {
        this.savingChanges = false;
      }
    },

    onCancel() {
      this.dialogVisible = false;
    },

    onAddbutton() {
      this.form.response.buttons.push({
        type: 'reply',
        text: ''
      });
    },

    async onAddKeyword() {
      if (this.keywordInputHolder) {
        this.form.keywords.push(this.keywordInputHolder);
      }
      this.keywordInputHolder = '';
    },

    async showDialog(customReplyId) {
      this.customReplyId = customReplyId;
      this.form = JSON.parse(JSON.stringify(this.formTemplate));
      this.isEditMode = false;

      // Fetch details if edit.
      if (customReplyId) {
        this.isEditMode = true;

        let response = await customReplyService.getDetails(customReplyId);
        let existingCustomReply = response.data;

        this.form.name = existingCustomReply.name;
        this.form.questions = existingCustomReply.questions;
        this.form.keywords = existingCustomReply.keywords ? existingCustomReply.keywords : [];
        this.form.response = existingCustomReply.response;
      }

      this.dialogVisible = true;
    }
  },

  mounted() {}
};
