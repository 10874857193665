<template>
  <div id="customReplyListPage" class="mt-5 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8">
      <!-- Header -->
      <div class="flex">
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            Custom Auto Reply
            <span v-if="!loading">({{ pageOptions.total }})</span>
          </div>
        </div>

        <div class="flex-none pl-2">
          <el-button type="primary" size="small" @click="createCustomReply">
            <i class="fa-sharp fa-solid fa-circle-plus"></i>
            &nbsp; Add New Custom Reply
          </el-button>
        </div>
      </div>

      <!-- Loader -->
      <div v-if="loading" class="w-full bg-white border rounded-lg mt-2 overflow-hidden" style="height: 60vh" v-loading="true"></div>

      <div v-else class="mt-4 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div class="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300" style="table-layout: fixed">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-600 sm:pl-6">Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Input</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Response</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Last Updated</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-if="customReplyList && customReplyList.length == 0">
                    <td class="py-60 text-center text-gray-500" colspan="6">No Custom Reply Found.</td>
                  </tr>

                  <template v-else>
                    <tr v-for="(customReply, index) in customReplyList" :key="index">
                      <td class="w-60 overflow-hidden py-4 pl-4 pr-3 text-sm font-medium text-gray-900">{{ customReply.name }}</td>
                      <td class="max-w-md px-3 py-4 text-sm text-gray-700" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ customReply.questions[0] }}</td>
                      <td class="max-w-md px-3 py-4 text-sm text-gray-700" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ customReply.response.text }}</td>
                      <td class="w-24 whitespace-nowrap py-4 pl-3 pr-4 text-xs text-gray-500">
                        <div>{{ customReply.updated_at }}</div>
                        <div>By {{ customReply.updated_by }}</div>
                      </td>
                      <td class="w-10 relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6">
                        <el-tooltip content="Edit">
                          <!-- <i class="fa-regular fa-pen-to-square"></i> -->
                          <i class="fa-regular fa-pen-to-square text-primary-600 cursor-pointer text-lg" @click="editCustomReply(index)"></i>
                        </el-tooltip>
                        &nbsp;
                        <el-tooltip content="Delete">
                          <i class="fa-regular fa-trash-can text-red-600 cursor-pointer text-lg" @click="deleteCustomReply(index)"></i>
                        </el-tooltip>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="7">
                        <div class="pagination font-semibold">
                          <div class="totalCount">Total {{ pageOptions.total }} Custom Reply</div>
                          <el-pagination background @current-change="fetchCustomReplys" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreateCustomReplyPopup ref="CreateCustomReplyPopup" @onRefresh="fetchCustomReplys(true)"></CreateCustomReplyPopup>
  </div>
</template>

<style lang="scss" src="./customReplyList.scss"></style>

<script>
import customReplyListComponent from './customReplyListComponent';
export default customReplyListComponent;
</script>
