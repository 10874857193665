<template>
  <el-dialog append-to-body custom-class="createCustomAutoReplyPopup" :title="popupTitle" :visible.sync="dialogVisible" width="800px">
    <div class="text-gray-800" v-if="form">
      <!-- Name -->
      <div class="p-5">
        <div>
          <div class="text-lg font-semibold">Name</div>
          <div class="text-gray-500 text-xs">Give a name to your Custom Auto Reply. Name is for your reference and will be visible to you only in dashboard.</div>
        </div>
        <div class="mt-2">
          <el-input v-model="form.name" placeholder="Welcome Message" maxlength="250" show-word-limit></el-input>
          <div v-if="formErrors && formErrors.name">
            <div class="text-red-500 text-xs">{{ formErrors.name }}</div>
          </div>
        </div>
      </div>

      <!-- Question List -->
      <div class="p-5 border-t">
        <div>
          <div class="text-lg font-semibold">Customer Input</div>
          <div class="text-gray-500 text-xs">Add the customer input for which you want the Custom Auto Reply to be triggered. You can enter the Input and up to 5 variations. In case the customer types in either of them, the Auto response gets triggered.</div>
        </div>

        <!-- Exact Match List -->
        <div class="mt-4">
          <div class="mb-2">
            <div class="mb-1">List Of Messages To Do Exact Match</div>

            <div class="mb-2" v-for="(question, index) in form.questions" :key="index">
              <div class="flex">
                <el-input size="small" class="flex-1" v-model="form.questions[index]" :placeholder="'Customer Input #' + (index + 1)" maxlength="250" show-word-limit></el-input>
                <div class="pl-2 w-10">
                  <el-button v-if="form.questions.length > 1" type="danger" circle plain icon="el-icon-delete" size="small" @click="form.questions.splice(index, 1)"></el-button>
                </div>
              </div>
              <div v-if="formErrors && formErrors.questions && formErrors.questions[index]">
                <div class="text-red-500 text-xs">{{ formErrors.questions[index] }}</div>
              </div>
            </div>
          </div>
          <el-button class="mt-4" v-if="form.questions.length < 5" plain type="info" size="mini" @click="form.questions.push('')">Add Variation</el-button>
        </div>

        <!-- Keywords -->
        <div class="mt-6">
          <div class="mb-2">
            <div class="mb-1">List Of Words To match In Message (If all words are matched then custom reply will be considered)</div>

            <el-tag class="mr-1.5" :key="index" v-for="(keyword, index) in form.keywords" closable :disable-transitions="false" @close="form.keywords.splice(index, 1)"> {{ keyword }} </el-tag>
            <el-input placeholder="mywebsite.com" v-model="keywordInputHolder" class="input-with-select" style="width: 300px" size="small" @keyup.enter.native="onAddKeyword">
              <el-button class="addPageButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddKeyword">Add</el-button>
            </el-input>
          </div>
        </div>
      </div>

      <!-- Response -->
      <div class="border-t p-5">
        <div class="">
          <div class="text-lg font-semibold">Custom Auto Reply</div>
          <div class="text-gray-500 text-xs">Configure the type of Auto reply you want to be triggered when a customer sends the user input or one of its variations in the chat.</div>
        </div>

        <!-- Message body -->
        <div class="">
          <div class="mt-4">
            <div class="font-normal text-black mb-1">Message Body</div>
            <el-input type="textarea" :rows="5" v-model="form.response.text" placeholder="Add Your Message Here" maxlength="1000" show-word-limit></el-input>
            <div v-if="formErrors && formErrors.response && formErrors.response.text">
              <div class="text-red-500 text-xs mt-1">{{ formErrors.response.text }}</div>
            </div>
          </div>

          <!-- Buttons Disabled For Now -->
          <!-- <div class="mt-6">
            <div class="font-normal text-black mb-1">Buttons (Optional)</div>
            <div class="mb-2 w-80">
              <div class="mb-2" v-for="(button, index) in form.response.buttons" :key="index">
                <div class="flex">
                  <el-input class="flex-1" v-model="button.text" :placeholder="'Button Text ' + (index + 1)" maxlength="20" show-word-limit></el-input>
                  <div class="pl-2 w-10 pt-1">
                    <el-button type="danger" circle plain icon="el-icon-delete" size="small" @click="form.response.buttons.splice(index, 1)"></el-button>
                  </div>
                </div>
                <div v-if="formErrors && formErrors.response && formErrors.response.buttons && formErrors.response.buttons[index]">
                  <div class="text-red-500 text-xs">{{ formErrors.response.buttons[index] }}</div>
                </div>
              </div>
            </div>

            <el-button class="mt-4" v-if="form.response.buttons.length < 3" plain type="info" size="mini" @click="onAddbutton">Add Button</el-button>
          </div> -->
        </div>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <div class="text-right w-full">
        <el-button size="medium" type="primary" :loading="savingChanges" @click="onValidateAndSave"> {{ isEditMode ? 'Save Changes' : 'Create Custom Reply' }} </el-button>
        <el-button size="medium" plain type="danger" @click="onCancel">Cancel</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import createCustomAutoReplyComponent from './createCustomAutoReplyPopupComponent';
export default createCustomAutoReplyComponent;
</script>

<style lang="scss">
@import 'src/assets/scss/_variables.scss';

.createCustomAutoReplyPopup {
}
</style>
