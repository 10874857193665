const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getList = function (params) {
  return axios.post(chatServiceUrl + '/automation/custom-reply/list', params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(chatServiceUrl + `/automation/custom-reply/${id}`, {
    headers: buildAuthHeaders()
  });
};

// exports.parseTemplateFromDashboardForm = function (params) {
//   return axios.post(chatServiceUrl + '/template/parse', params, {
//     headers: buildAuthHeaders()
//   });
// };

exports.create = function (params) {
  return axios.post(chatServiceUrl + '/automation/custom-reply', params, {
    headers: buildAuthHeaders()
  });
};

exports.update = function (id, params) {
  return axios.put(chatServiceUrl + `/automation/custom-reply/${id}`, params, {
    headers: buildAuthHeaders()
  });
};

exports.delete = function (id) {
  return axios.delete(chatServiceUrl + `/automation/custom-reply/${id}`);
};
